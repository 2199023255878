<template>
	
	<div class="web-view my-5 py-5">
		<b-row>
			<b-col cols="12 mb-5">
				<div class="text-center m-auto">
					<b-link class="brand-logo">
						<img src="@/assets/images/logo/stocks_easy_logo.png" alt="logo" class="logo-img" />
					</b-link>
				</div>
				<div class=" mt-5" v-if="loader">
					<div class="cubeLoader">
						<div class="cube"></div>
						<div class="cube"></div>
						<div class="cube"></div>
						<div class="cube"></div>
					</div>
				</div>
			</b-col>
			<b-col cols="12" v-if="loader">
				<div class="text-center">
					<h2>
						Please do not refresh the page.<br/> We are progressing your payment.
					</h2>
				</div>
			</b-col>
      <b-col cols="12" v-if="!loader">
				<div class="text-center">
          <validation-observer ref="loginForm" v-slot="{ handleSubmit }">
            <b-form
              class="auth-login-form mt-2 text-left"
              @submit.prevent="handleSubmit(submit)"
            >
              
              <b-col
                sm="12"
                md="6"
                class="mx-auto mb-1"
              >
                <InputField
                  :id="'full-name'"
                  :placeholder="'Full Name'"
                  :describedby="'full-name-feedback'"
                  :label="'Full Name *'"
                  :name="'full Name'"
                  :rules="{ required: true, min: 3, alpha_spaces: true }"
                  :modelValue="formData.fullName"
                  @input="val => formData.fullName = val"
                />
              </b-col>

              <b-col
                sm="12"
                md="6"
                class="mx-auto mb-1"
              >
                <InputField
                  :id="'email'"
                  :placeholder="'Email'"
                  :describedby="'email-feedback'"
                  :label="'Email *'"
                  :name="'email'"
                  :rules="{ required: true, email }"
                  :modelValue="formData.email"
                  @input="val => formData.email = val"
                />
              </b-col>

              <b-col
                sm="12"
                md="6"
                class="mx-auto"
              >
                <b-row >
                  <b-col
                    sm="12"
                    md="5"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Country Code *"
                      label-for="phone"
                      class="mt-0"
                    >
                      <div class="">
                        <vue-country-code
                          id="country-code"
                          class="w-100 "
                          :enabledFlags="true"
                          :enabledCountryCode="true"
                          :disabledFetchingCountry="true"
                          defaultCountry="IN"
                          @onSelect="onSelect"
                          :preferredCountries="['vn', 'us', 'gb']">
                        </vue-country-code>
                      </div>
                    </b-form-group>
                  </b-col>
      
                  <b-col
                    sm="12"
                    md="7"
                    class="mb-1"
                  >
                    <InputField :id="'phone'" :placeholder="'Phone'" :describedby="'phone-feedback'" :label="'Phone *'" :name="'Phone'" :rules="{ required: true, integer: true}" :modelValue="formData.phone" @input="val => formData.phone = val" :type="'number'"/>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3" class="mx-auto" >
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  class="p-1 mt-lg-1 submit-btn"
                >
                  Submit
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </div>
      </b-col>
		</b-row>
    <b-modal size="sm" id="modal-payment-img" title="BootstrapVue" centered ref="paymentImageModal" hide-footer @hide="onQRModalClose()">
      <template #modal-header="">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">Payment ORCode </h2>
        </div>
      </template>
      <div v-if="loadingImg" class="d-flex align-items-center justify-content-center">
        Loading QRCode...
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
      <h5 class="ml-auto d-flex justify-content-center">Time left: {{ secondCountdown }} second</h5>
      <div class="overflow-hidden">
        <img class="payment-img" :src="paymentImg" alt="paymentImg" width="100%" @load="onImageLoad"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { inject, onMounted, reactive, ref, onUnmounted } from '@vue/composition-api'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import VueCountryCode from "vue-country-code";
import InputField from '@/views/components/form/InputField.vue';
import store from "@/store";

export default {
  components: {
    InputField,
    VueCountryCode,
  },
  setup(props, context) {
    const $http = inject('$http');
    const router = context.root.$router;
    const loader = ref(false)
    const formData = reactive({
      phone:'',
      fullName:'',
      countryCode:'',
      email: ''
    })
    const paymentImg = ref(null);
    const subscriptionID = ref(null);
    const paymentImageModal = ref(null);
    const loadingImg = ref(true);
    const onImageLoad = () => {
      console.log('load');
      loadingImg.value = false;
    }

    const countdown = ref(300);
    const secondCountdown = ref(300);
    let intervalId = ref(null);
    let secIntervalId = ref(null);
    const getStatus = async () => {
      await $http
        .get(`/payment/status?id=${subscriptionID.value}&type=qrcode_link`)
        .then((response) => {
          if(response.data.status == "paid") {
            router.push('/kyc-sign-up')
            clearAllInterval()
          } else if (response.data.status == "pending" && secondCountdown.value == 0) {
            paymentImageModal.value.hide();
          } else if (response.data.status == "failed") {
            paymentImageModal.value.hide();
            clearAllInterval()
          }
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const startPolling = () => {
      intervalId = setInterval(() => {
        countdown.value -= 5;
        if (countdown.value <= 0) {
          clearInterval(intervalId);
        }
        getStatus();
      }, 5000);

      onUnmounted(() => {
        clearInterval(intervalId);
      });
    };

    const updateCountdownDisplay = () => {
      secIntervalId = setInterval(() => {
        secondCountdown.value--;
        if (secondCountdown.value <= 0) {
          clearInterval(secIntervalId);
        }
      }, 1000);

      onUnmounted(() => {
        clearInterval(secIntervalId);
      });
    };

    const submit = async () => {
      loader.value = true
      const data = {
        packageID: router.currentRoute.params.packageID,
        optionID: router.currentRoute.params.optionID,
        fullName: formData.fullName,
        phone: formData.phone,
        email: formData.email,
        pcc: formData.countryCode
      }
      $http.post(`/advisor/subscriptions/pay`, data)
        .then(async (response) => {
          countdown.value = 300;
          secondCountdown.value = 300;
          loader.value = false
          if(response.status == 200) {
            await store.dispatch('KYCSignUp/storeSubscriptionDetails', response)
            if(response.data.linkType == "qrcode_link") {
              loadingImg.value = true
              paymentImg.value = response.data.paymentLink
              subscriptionID.value = response.data.subscriptionID
              paymentImageModal.value.show();
              startPolling();
              updateCountdownDisplay();
            } else {
              window.open(response.data.paymentLink, "_self");
            }
          } else {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: `Error`,
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: "Something want to wrong",
                closeOnClick: false,
                timeout: false,
              },
            })
          }
        })
        .catch((error) => {
          loader.value = false
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          })
        });
    }
    const onSelect = ({name, iso2, dialCode}) => {
      formData.countryCode = `${dialCode}`
    }

    const clearAllInterval = () => {
      clearInterval(intervalId);
      clearInterval(secIntervalId);
    }
    const onQRModalClose = () => {
      clearAllInterval();
    }

    onMounted(() => {
     
    })
    return {
			submit,
      formData,
      onSelect,
      loader,
      paymentImg,
      paymentImageModal,
      onImageLoad,
      loadingImg,
      countdown,
      secondCountdown,
      onQRModalClose
    };
    }

}
</script>

<style lang="scss">

.cubeLoader {
  width: 100px;
  height: 100px;
  transform: rotate(-45deg);
  margin: 0 auto;
}
.cubeLoader .cube {
  height: 20px;
  width: 20px;
  margin: 15px;
  background-image: linear-gradient(90deg, #1e60e6 0%, #00d8dd 35%, #1e60e6 100%);
  float: right;
  animation: anim 2.5s infinite;
}
.cubeLoader .cube:nth-child(1) {
  animation-name: cubeMoveAnim1-ltr, cubeAnim;
}
.logo-img {
  height: 60px;
}
.submit-btn {
  font-size: 18px;
}
@keyframes cubeMoveAnim1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50px, 0);
  }
}
.cubeLoader .cube:nth-child(2) {
  animation-name: cubeMoveAnim2, cubeAnim;
}
@keyframes cubeMoveAnim2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 50px);
  }
}
.cubeLoader .cube:nth-child(3) {
  animation-name: cubeMoveAnim3, cubeAnim;
}
@keyframes cubeMoveAnim3 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -50px);
  }
}
.cubeLoader .cube:nth-child(4) {
  animation-name: cubeMoveAnim4-ltr, cubeAnim;
}
@keyframes cubeMoveAnim4 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50px, 0);
  }
}

@keyframes cubeAnim {
  0% {
    border-radius: 0px;
  }
  40% {
    border-radius: 20px;
  }
  60% {
    border-radius: 20px;
  }
  100% {
    border-radius: 0px;
  }
}
  
.payment-img {
  margin-top: -150px;
}

</style>