<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-slot="validationContext"
  >
		<b-form-group
      :label="label"
      :label-for="id"
    >
      <b-form-textarea
        v-if="type == 'textarea'"
        :id="id"
        size="sm"
        v-model="values"
        :name="name"
        :placeholder="placeholder"
        class=""
        :state="!showValidationFeedback && showValidationFeedbackmes ? getValidationState(validationContext) : null"
        :class="{ 'is-invalid': showValidationFeedback && showValidationFeedbackmes && !validationContext.valid }"
        :aria-describedby="describedby"
        @input="changeValue"
      />
      <b-form-input
        v-else
        :id="id"
        v-model="values"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :state="!showValidationFeedback && showValidationFeedbackmes ? getValidationState(validationContext):null"
        :class="{ 'is-invalid': showValidationFeedback&& showValidationFeedbackmes && !validationContext.valid }"
        :aria-describedby="describedby"
        @input="changeValue"
        :type="type"
      />
      <b-form-invalid-feedback  v-if="showValidationFeedbackmes" :id="describedby"> {{ validationContext.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
export default {
	props: {
    id: {
      type: String,
      required: true,
    },
		rules: {
      type: Object,
      required: true,
    },
		name: {
      type: String,
      required: true,
    },
		label: {
      type: String,
      required: true,
    },
		modelValue: {
      type: [String, Number, null],
      required: true,
    },
		placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
		describedby: {
			type: String,
			required: true
		},
    type:{
      type: String,
      default: 'text'
    },
    showValidationFeedback:{
      type:Boolean,
      default:false
    },
    showValidationFeedbackmes:{
      type:Boolean,
      default:true
    }
  },
  setup(props, { emit }) {
		
		const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    }

		const values = props.modelValue

		const changeValue = (event) => {
			emit('input', event)
		}

		return {
      getValidationState,
			changeValue,
			values
		}
	}
}
</script>